import React, { useState, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { v4 as uuidv4 } from 'uuid'
import { linkResolver } from '../util/linkResolver'
import { formatDate, formatDuration } from '../util/timeDateUtils'

import { MediaIcon } from '../components/ContentBlocks/MediaLibraryCommon'

import SEO from '../components/SEO'
// import GoogleAds from '../components/GoogleAds'
import Layout from '../components/Layout'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'

import { getGlobal } from '../util/global'
import { DISPLAY_WIDTH } from '../util/constants'

import PodcastBlock from '../components/ContentBlocks/PodcastBlock'
import CmeSidebar from '../components/ContentBlocks/CmeSidebar'
import VideoBlock from '../components/ContentBlocks/VideoBlock'
import { RegionContainer } from '../components/styles/Utility.styles'
import WebinarPresentersList from '../components/ContentBlocks/WebinarPresentersList'
import CtaLink from '../components/ContentBlocks/CtaLink'
import SocialSharingBlock from '../components/ContentBlocks/SocialSharingBlock'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'
import CmeMediaLibraryItemPageRelatedContent from '../components/ContentBlocks/CmeMediaLibraryItemPageRelatedContent'
import { HcpdBackArrow } from '../components/Icons'
import * as Styled from '../components/Heroes/styles/MediaItemPageHero.styles'
import { sliceBottomPadding } from '../util/helper'

const BackButton = () => (
  <Styled.BackButtonContainer to="/cme-library/">
    <HcpdBackArrow />
    <span>Media for Health Care Providers</span>
  </Styled.BackButtonContainer>
)

const InjectSchema = ({ node }) => {
  switch (node.media_type?.type) {
    case 'Podcast': {
      const podcastBlockNode = node.body
        .filter(media => media.type === 'podcast')
        .slice(0, 1)
        .pop().primary
      return (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "AudioObject",
              "description": "${
                podcastBlockNode.schema_podcast_description
                  ? podcastBlockNode.schema_podcast_description
                  : ''
              }",
              "duration": "${formatDuration(
                podcastBlockNode.podcast_duration_hours,
                podcastBlockNode.podcast_duration_minutes,
                podcastBlockNode.podcast_duration_seconds
              )}",
              "uploaddate": "${
                podcastBlockNode.podcast_episode_date
                  ? podcastBlockNode.podcast_episode_date.replace(/-/g, '')
                  : ''
              }",
              "name": "${node.title?.slice(0, 1).pop().text ?? ''}",
              "publisher": {
                "@type": "Organization",
                "name": "National Psoriasis Foundation",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.psoriasis.org/sites/all/themes/national_psoriasis_foundation/logo.png"
                }
              },
              "thumbnailUrl": "${node.hero_image?.url ?? ''}"
            }
          `}
          </script>
        </Helmet>
      )
    }
    // case 'Webinar':
    //   return null
    // case 'Video':
    //   // we're already doing this in the Video Block element, so skip it here.
    //   return null
    default:
      return null
  }
}

const UpcomingWebinarTimeField = ({ data }) => {
  const [eventTimeString, setEventTimeString] = useState(null)
  useEffect(() => {
    setEventTimeString(
      data.body
        ?.filter(node => node.slice_type === 'upcoming_webinar')
        ?.slice(0, 1)
        ?.pop()?.primary?.event_time ?? null
    )
  }, [data])

  return (
    <>
      {eventTimeString && (
        <Styled.MediaItemDate>
          {eventTimeString} /{' '}
          <a href="https://www.timeanddate.com/worldclock/converter.html">
            Time Zone Converter
          </a>
        </Styled.MediaItemDate>
      )}
    </>
  )
}

const MediaItemPageHero = ({ data, doc, siteSettings }) => {
  const [isUpcoming, setIsUpcoming] = useState(false)
  const [hasCredits, setHasCredits] = useState(false)

  useEffect(() => {
    if (!data.datestamp) {
      return null
    }
    const today = new Date()
    const eventDate = new Date(data.datestamp)
    if (eventDate >= today) {
      setIsUpcoming(true)
    }
    setHasCredits(
      Number(data.credits?.document?.data?.credits) !== 0 &&
        data.credits?.document?.data.credits !== 'Non-CME'
    )
  }, [data])

  // cme-events linkresolver workaround
  useEffect(() => {
    const isBrowser = typeof document !== 'undefined'
    if (isBrowser) {
      const bodyLinks = document.querySelectorAll('main a')
      bodyLinks.forEach(bodyLink => {
        const linkHref = bodyLink.getAttribute('href')
        if (linkHref === 'cme-events/') {
          bodyLink.setAttribute('href', `/${linkHref}`)
        }
      })
    }
  }, [])

  return (
    <Styled.HeroContainer>
      <Styled.LogoTopicDateRegion>
        <MediaIcon type={data.media_type?.document?.data?.type} />
        <Styled.TopicAndDateBox>
          <Styled.Topic>
            {data.topic?.document?.data?.topic}
            {data.show_cme_tag !== false && <span>&nbsp;*CME</span>}
          </Styled.Topic>
          <Styled.MediaItemDate>
            {formatDate(data.datestamp)}
          </Styled.MediaItemDate>
          {/* {data.media_type?.document?.data?.type === 'Webinar' &&
            isUpcoming && <UpcomingWebinarTimeField data={data} />} */}
          <UpcomingWebinarTimeField data={data} />
        </Styled.TopicAndDateBox>
      </Styled.LogoTopicDateRegion>
      <Styled.TextRegion>
        <Styled.MediaItemTitle>
          {data.title?.richText && (
            <PrismicRichText field={data.title.richText} />
          )}
        </Styled.MediaItemTitle>
        {data.link_to_credits_and_disclosures && hasCredits && (
          <Styled.CmeCtaContainer
          // $additionalStyles={
          //   doc.media_type?.document?.data?.type === 'Podcast'
          //     ? Styled.CmeCtaPodcastStyles
          //     : doc.media_type?.document?.data?.type === 'Video'
          //     ? Styled.CmeCtaVideoStyles
          //     : null
          // }
          >
            {data.credits?.document?.data?.credits !== '0.00' && (
              <Styled.CmeCtaCredits>
                <Styled.CmeCtaCreditsValue>
                  {data.credits?.document?.data?.credits}
                </Styled.CmeCtaCreditsValue>
                <span>Credits</span>
              </Styled.CmeCtaCredits>
            )}
            <Styled.CmeCtaTextWrapper>
              {data.link_to_credits_and_disclosures?.link_type === 'Web' ? (
                <Styled.CmeCtaTextLink
                  as="a"
                  href={data.link_to_credits_and_disclosures.url}
                  target="_blank"
                >
                  {
                    // eslint-disable-next-line no-extra-parens
                    (doc.media_type?.document?.data?.type === 'Webinar' &&
                      isUpcoming) ||
                    data.credits?.document?.data?.credits === '0.00' ? (
                      <p>View CME Credits &amp; Disclosures</p>
                    ) : (
                      <p>Claim CME Credits &amp; View Disclosures</p>
                    )
                  }
                  <svg
                    width="45"
                    height="45"
                    fill="#fff"
                    viewBox="0 0 45 46"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="rollover"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.5 45.5C34.9264 45.5 45 35.4264 45 23C45 10.5736 34.9264 0.5 22.5 0.5C10.0736 0.5 0 10.5736 0 23C0 35.4264 10.0736 45.5 22.5 45.5ZM21.1185 16.1367C21.5168 15.7755 22.1242 15.7755 22.5225 16.1367L29.8243 22.7593C30.2619 23.1562 30.2619 23.8438 29.8243 24.2407L22.5225 30.8633C22.1242 31.2245 21.5168 31.2245 21.1185 30.8633C20.6615 30.4488 20.6612 29.7308 21.1178 29.3159L26.4182 24.5H14C13.4477 24.5 13 24.0523 13 23.5C13 22.9477 13.4477 22.5 14 22.5H26.4182L21.1178 17.6841C20.6612 17.2692 20.6615 16.5512 21.1185 16.1367Z"
                    />
                    <path
                      className="normal"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M43 23a20.5 20.5 0 11-41 0 20.5 20.5 0 0141 0zm2 0a22.5 22.5 0 11-45 0 22.5 22.5 0 0145 0zm-23.88-6.86c.4-.36 1-.36 1.4 0l7.3 6.62a1 1 0 010 1.48l-7.3 6.62a1.05 1.05 0 01-1.4-1.54l5.3-4.82H14a1 1 0 110-2h12.42l-5.3-4.82a1.04 1.04 0 010-1.54z"
                    />
                  </svg>
                </Styled.CmeCtaTextLink>
              ) : (
                <Styled.CmeCtaTextLink
                  to={linkResolver(data.link_to_credits_and_disclosures)}
                >
                  {doc.media_type?.document?.data?.type === 'Webinar' &&
                  isUpcoming ? (
                    <p>View CME Credits &amp; Disclosures</p>
                  ) : (
                    <p>Claim CME Credits &amp; View Disclosures</p>
                  )}
                  <svg
                    width="45"
                    height="45"
                    fill="#fff"
                    viewBox="0 0 45 46"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="rollover"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.5 45.5C34.9264 45.5 45 35.4264 45 23C45 10.5736 34.9264 0.5 22.5 0.5C10.0736 0.5 0 10.5736 0 23C0 35.4264 10.0736 45.5 22.5 45.5ZM21.1185 16.1367C21.5168 15.7755 22.1242 15.7755 22.5225 16.1367L29.8243 22.7593C30.2619 23.1562 30.2619 23.8438 29.8243 24.2407L22.5225 30.8633C22.1242 31.2245 21.5168 31.2245 21.1185 30.8633C20.6615 30.4488 20.6612 29.7308 21.1178 29.3159L26.4182 24.5H14C13.4477 24.5 13 24.0523 13 23.5C13 22.9477 13.4477 22.5 14 22.5H26.4182L21.1178 17.6841C20.6612 17.2692 20.6615 16.5512 21.1185 16.1367Z"
                    />
                    <path
                      className="normal"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M43 23a20.5 20.5 0 11-41 0 20.5 20.5 0 0141 0zm2 0a22.5 22.5 0 11-45 0 22.5 22.5 0 0145 0zm-23.88-6.86c.4-.36 1-.36 1.4 0l7.3 6.62a1 1 0 010 1.48l-7.3 6.62a1.05 1.05 0 01-1.4-1.54l5.3-4.82H14a1 1 0 110-2h12.42l-5.3-4.82a1.04 1.04 0 010-1.54z"
                    />
                  </svg>
                </Styled.CmeCtaTextLink>
              )}
            </Styled.CmeCtaTextWrapper>
          </Styled.CmeCtaContainer>
        )}
        <MediaItem node={doc} npfLogo={siteSettings.node?.npf_logo} />
        {doc.media_type?.document?.data?.type !== 'Podcast' && (
          <Styled.MediaItemDescription>
            {data.body_text?.richText && (
              <PrismicRichText field={data.body_text.richText} />
            )}
          </Styled.MediaItemDescription>
        )}
        {doc.media_type?.document?.data?.type === 'Podcast' && (
          <Styled.MediaItemDescription>
            {data.body_text?.richText && (
              <PrismicRichText field={data.body_text.richText} />
            )}
          </Styled.MediaItemDescription>
        )}
      </Styled.TextRegion>
    </Styled.HeroContainer>
  )
}

const MediaItem = ({ node, npfLogo }) => {
  switch (node.media_type?.document?.data?.type) {
    case 'Podcast': {
      const podcastNode = node.body
        ?.filter(block => block.slice_type === 'podcast')
        ?.slice(0, 1)
        .pop()
      if (!podcastNode) {
        return null
      }
      return (
        <>
          <PodcastBlock data={podcastNode.primary} />
          <WebinarPresentersList
            presenters={
              node.body
                ?.filter(block => block.slice_type === 'webinar_presenter_s_')
                ?.slice(0, 1)
                ?.pop()?.items ?? null
            }
          />
        </>
      )
    }
    case 'Webinar':
      {
        // Webinars are tricky because they can be either video_block elements or upcoming_webinar elements
        const webinarNode = node.body
          ?.filter(block => block.slice_type === 'upcoming_webinar')
          ?.slice(0, 1)
          .pop()
        const webinarVideoNode = node.body
          ?.filter(block => block.slice_type === 'video_block')
          ?.slice(0, 1)
          .pop()
        if (!webinarNode && !webinarVideoNode) {
          return null
        }
        if (webinarNode) {
          const webinarNodeBottomPaddingAdjustment = sliceBottomPadding(
            webinarNode?.primary?.bottom_padding_adjustment
          )
          return (
            <>
              <RegionContainer>
                <div style={{ gridColumn: '2 / 3', paddingBottom: '40px' }}>
                  <CtaLink
                    text="Register Now"
                    link={webinarNode.primary.registration_link}
                  />
                </div>
              </RegionContainer>
              <WebinarPresentersList
                presenters={
                  node.body
                    ?.filter(
                      block => block.slice_type === 'webinar_presenter_s_'
                    )
                    ?.slice(0, 1)
                    ?.pop()?.items ?? null
                }
              />
              {/* add disclaimer */}
            </>
          )
        } else if (webinarVideoNode) {
          const webinarVideoNodeBottomPaddingAdjustment = sliceBottomPadding(
            webinarVideoNode?.primary?.bottom_padding_adjustment
          )
          return (
            <>
              <VideoBlock
                data={webinarVideoNode.primary}
                bottomPaddingAdjustment={
                  webinarVideoNodeBottomPaddingAdjustment
                }
              />
              <WebinarPresentersList
                presenters={
                  node.body
                    ?.filter(
                      block => block.slice_type === 'webinar_presenter_s_'
                    )
                    ?.slice(0, 1)
                    ?.pop()?.items ?? null
                }
              />
            </>
          )
        }
      }
      break
    // return <pre>{JSON.stringify(node)}</pre>
    case 'Video': {
      const videoNode = node.body
        ?.filter(block => block.slice_type === 'video_block')
        ?.slice(0, 1)
        .pop()
      if (!videoNode) {
        return null
      }
      const bottomPaddingAdjustment = sliceBottomPadding(
        videoNode?.primary?.bottom_padding_adjustment
      )
      return (
        <VideoBlock
          data={{ ...videoNode.primary, npfLogo }}
          bottomPaddingAdjustment={bottomPaddingAdjustment}
        />
      )
    }

    default:
      return null
  }
}

const CmeMediaLibraryItemPage = ({ data }) => {
  const global = getGlobal()
  const href = global?.location?.href
  const siteSettings = data.prismicGeneralSiteSettings.data
  const doc = data.prismicCmeMediaLibraryItem?.data
  if (!doc) {
    return null
  }
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <InjectSchema node={doc} />
      <Layout renderAnchor={false}>
        {/* <pre>{JSON.stringify(doc, false, 2)}</pre> */}
        <RegionContainer>
          <BackButton />
        </RegionContainer>
        <div className="two-column-layout" data-layout="two-column">
          <div className="two-column-layout__wrapper">
            <div className="two-column-layout__main">
              <MediaItemPageHero
                data={doc}
                doc={doc}
                siteSettings={siteSettings}
              />
            </div>
            <div className="two-column-layout__aside">
              <div className="util-large-only">
                <CmeSidebar />
              </div>
            </div>
          </div>
        </div>
        {
          // Don't render hero image for Video pages
          doc.hero_image &&
            doc.media_type?.document?.data?.type !== 'Video' &&
            doc.media_type?.document?.data?.type !== 'Podcast' &&
            doc.media_type?.document?.data?.type !== 'Webinar' && (
              <RegionContainer>
                <Styled.HeroImage>
                  <source
                    media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
                    srcSet={doc.hero_image.thumbnails.mobile.url}
                  />
                  <source
                    media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
                    srcSet={doc.hero_image.url}
                  />
                  <img src={doc.hero_image.url} alt={doc.hero_image.alt} />
                </Styled.HeroImage>
              </RegionContainer>
            )
        }
        {doc.body &&
          doc.body
            .filter(
              block =>
                block.slice_type !== 'podcast' &&
                block.slice_type !== 'video_block' &&
                block.slice_type !== 'upcoming_webinar'
            )
            .map(block => <SliceRenderer key={block} block={block} />)}
        <SocialSharingBlock
          title={seoTitle}
          description={doc.meta_description}
          url={href}
        />
        <CmeMediaLibraryItemPageRelatedContent
          type={doc.media_type.document.data.type}
          activeItem={doc.uid}
        />
        <div className="util-small-only">
          <CmeSidebar />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query CmeMediaLibraryItemPageQuery($id: String) {
    prismicGeneralSiteSettings {
      data {
        webinar_disclaimer_text {
          text
        }
        npf_logo {
          url
          alt
        }
        media_library_item_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        media_library_item_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicCmeMediaLibraryItem(id: { eq: $id }) {
      id
      uid
      data {
        datestamp
        show_cme_tag
        content_expiration_date
        display_name
        body_text {
          richText
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        hero_image {
          alt
          url
          thumbnails {
            library_hero_desktop {
              url
            }
            library_hero_mobile {
              url
            }
            library_thumbnail {
              url
            }
            mobile {
              url
            }
          }
        }
        link_to_credits_and_disclosures {
          url
          uid
          type
          link_type
          id
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        title {
          richText
          text
        }
        credits {
          document {
            ... on PrismicCmeMediaLibraryItemCredits {
              id
              data {
                credits
              }
            }
          }
        }
        media_type {
          document {
            ... on PrismicCmeMediaLibraryItemType {
              id
              data {
                type
              }
            }
          }
        }
        topic {
          document {
            ... on PrismicCmeMediaLibraryItemTopic {
              id
              data {
                topic
              }
            }
          }
        }
        body {
          ... on PrismicCmeMediaLibraryItemDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodyPodcast {
            id
            slice_type
            primary {
              podcast_duration_hours
              podcast_duration_minutes
              podcast_duration_seconds
              podcast_episode_date
              schema_podcast_description
              transcription_link {
                url
                uid
                type
                link_type
                id
              }
              embed_code {
                text
              }
              next_podcast {
                url
                uid
                type
                link_type
                id
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodyUpcomingWebinar {
            id
            slice_type
            primary {
              event_time
              registration_link {
                id
                link_type
                type
                uid
                url
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
          ... on PrismicCmeMediaLibraryItemDataBodyWebinarPresenterS {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              presenter_name
              presenter_title
              presenter_type
              presenter_image {
                url
                alt
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CmeMediaLibraryItemPage
