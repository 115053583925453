import React, { useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import * as Styled from './styles/MediaLibraryItemPageRelatedContent.styles'
import { RegionContainer } from '../styles/Utility.styles'
import { ItemsGridContainer } from '../styles/MediaLibrary.styles'
import CmeMediaLibraryItem from './CmeMediaLibraryItem'

/*
This is not a good idea... These IDs could conceivably change,
and, really, we should be using both media type and topic here,
not just type.
What "Should" be happening here is a custom resolver in
gatsby-node that maps UIDs to media types and topics

This is a "short-term" fix for that. We'll just bake in the Prismic
IDs for the three existing media types.
*/
const MEDIA_LIBRARY_ITEM_RELATED_CONTENT = graphql`
  {
    Video: allPrismicCmeMediaLibraryItem(
      filter: { data: { media_type: { id: { eq: "YQl-3hMAACMAg8ib" } } } }
      sort: { fields: data___datestamp, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            show_cme_tag
            title {
              text
              richText
            }
            credits {
              document {
                ... on PrismicCmeMediaLibraryItemCredits {
                  data {
                    credits
                  }
                }
              }
            }
            topic {
              document {
                ... on PrismicCmeMediaLibraryItemTopic {
                  data {
                    topic
                  }
                }
              }
            }
            media_type {
              document {
                ... on PrismicCmeMediaLibraryItemType {
                  data {
                    type
                  }
                }
              }
            }
            hero_image {
              url
              alt
              thumbnails {
                mobile {
                  url
                }
                library_thumbnail {
                  url
                }
                library_hero_mobile {
                  url
                }
                library_hero_desktop {
                  url
                }
              }
            }
          }
        }
      }
    }
    Podcast: allPrismicCmeMediaLibraryItem(
      filter: { data: { media_type: { id: { eq: "YQl-yRMAACQAg8g5" } } } }
      sort: { fields: data___datestamp, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            show_cme_tag
            title {
              text
              richText
            }
            credits {
              document {
                ... on PrismicCmeMediaLibraryItemCredits {
                  data {
                    credits
                  }
                }
              }
            }
            topic {
              document {
                ... on PrismicCmeMediaLibraryItemTopic {
                  data {
                    topic
                  }
                }
              }
            }
            media_type {
              document {
                ... on PrismicCmeMediaLibraryItemType {
                  data {
                    type
                  }
                }
              }
            }
            hero_image {
              url
              alt
              thumbnails {
                mobile {
                  url
                }
                library_thumbnail {
                  url
                }
                library_hero_mobile {
                  url
                }
                library_hero_desktop {
                  url
                }
              }
            }
          }
        }
      }
    }
    Webinar: allPrismicCmeMediaLibraryItem(
      filter: { data: { media_type: { id: { eq: "YQl-7xMAACYAg8ju" } } } }
      sort: { fields: data___datestamp, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            show_cme_tag
            title {
              text
              richText
            }
            credits {
              document {
                ... on PrismicCmeMediaLibraryItemCredits {
                  data {
                    credits
                  }
                }
              }
            }
            topic {
              document {
                ... on PrismicCmeMediaLibraryItemTopic {
                  data {
                    topic
                  }
                }
              }
            }
            media_type {
              document {
                ... on PrismicCmeMediaLibraryItemType {
                  data {
                    type
                  }
                }
              }
            }
            hero_image {
              url
              alt
              thumbnails {
                mobile {
                  url
                }
                library_thumbnail {
                  url
                }
                library_hero_mobile {
                  url
                }
                library_hero_desktop {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const RelatedItemRow = ({ data, type, activeItem }) => {
  const [subset, setSubset] = useState([])
  useEffect(() => {
    const edges = data[type].edges
    shuffleArray(edges)
    setSubset([
      ...edges.filter(item => item.node.uid !== activeItem).slice(0, 3),
    ])
  }, [])

  return (
    <ItemsGridContainer>
      {/* <h1>{activeItem}</h1>
      <pre>{JSON.stringify(subset, null, 2)}</pre> */}
      {subset.slice(0, 3).map(item => (
        <CmeMediaLibraryItem key={item.node.uid} item={item} />
      ))}
    </ItemsGridContainer>
  )
}

const CmeMediaLibraryItemPageRelatedContent = ({ type, activeItem }) => (
  <StaticQuery
    query={`${MEDIA_LIBRARY_ITEM_RELATED_CONTENT}`}
    render={data => {
      if (!data) {
        return null
      }
      return (
        <>
          <RegionContainer>
            <Styled.HeaderContainer>
              <Styled.RelatedContentCircleAccent />
              <Styled.RelatedContentSectionHeader>
                Related Items
              </Styled.RelatedContentSectionHeader>
            </Styled.HeaderContainer>
          </RegionContainer>
          <RegionContainer>
            <RelatedItemRow data={data} type={type} activeItem={activeItem} />
          </RegionContainer>
        </>
      )
    }}
  />
)

export default CmeMediaLibraryItemPageRelatedContent
